import axios from 'axios';
import React, { useState } from 'react';
import { useAuth } from '../context/auth';
import toast, { Toaster } from "react-hot-toast";

function Withdraw() {
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [withdrawlLoading, setWithdrawlLoading] = useState(false)
  const [auth] = useAuth(); // Only destructure `auth` if `setAuth` is not used

  const withdrawUSDT = async (amount) => {
    console.log("withdraw===>", amount);
    setWithdrawlLoading(true)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/Withdrawl/${auth?.user?._id}`,
        { amount }
      );

      if (response.data.success) {
        toast.success(response.data.message);
      }else{
        toast.error(response.data.message);
      }
      console.log('Withdrawal Response:', response.data); // Use `response.data` for Axios
    } catch (error) {
      console.error('Withdrawal Error:', error);
      toast.error(error.response.data.message)
    }
    setWithdrawlLoading(false)
  };

  return (
    <div>
       <Toaster/>
      <div className="bg-slate-800 p-4 border-l-4 border-purple-600">
        <p className="text-sm font-semibold text-left text-white">Balance:</p>
        <div className="flex mt-4 gap-2 items-center">
          <img src="/images/pl.png" className="w-8" alt="error" />
          <p className="text-yellow-500 font-bold text-2xl">
            ${auth?.user?.earningWallet || 0}
          </p>
        </div>
        <hr className="mt-3" />
        <p className="text-sm text-gray-400 text-left mt-3">Request Withdraw:</p>
        <input
          type="number"
          placeholder="Enter amount"
          value={withdrawAmount}
          onChange={(e) => setWithdrawAmount(e.target.value)}
          className="w-full h-10 rounded-full text-center text-black font-bold mt-4"
        />
        <button
              onClick={() => withdrawUSDT(withdrawAmount)}
              disabled={withdrawlLoading}
              className={`mt-4 border-b-4 border-green-500 bg-yellow-600 hover:bg-yellow-700 text-white font-semibold py-2 px-4 rounded-full w-full ${
                withdrawlLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {withdrawlLoading ? "Loading..." : "WITHDRAW"}
            </button>

        <p className="text-gray-400 mt-3 font-sans">
          "*In the event of an unsuccessful withdrawl resulting in a zero
          balance display, re-initiate the withdrawl process, disregarding the
          current balance state. This will re-trigger the pending withdrawl
          request, facilitating the successful transfer of funds to your
          designated wallet address."
        </p>
      </div>
    </div>
  );
}

export default Withdraw;
